<template>
  <div class="done">
    <div class="top">
      <el-alert :title="`您正在查看${followUpName} ${typeName} ${name}的完成情况`" type="warning"></el-alert>
    </div>
    <el-form inline class="form-content mt20">
      <el-form-item label="填写人">
        <el-input placeholder="输入关键字" v-model="search" class="wd200"></el-input>
      </el-form-item>
      <el-form-item :label="$route.query.followUpType==2?'阅读状态':'填写状态'">
        <el-select
          placeholder="请选择状态"
          v-model="status"
          clearable
          style="width:200px;"
          v-if="$route.query.followUpType==2"
        >
          <el-option
            v-for="(item,index) in [{name:'已阅读',value:1},{name:'未阅读',value:2}]"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
        <el-select placeholder="请选择状态" v-model="status" clearable style="width:200px;" v-else>
          <el-option
            v-for="(item,index) in [{name:'已填写',value:1},{name:'未填写',value:2}]"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getPatience">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table class="table" :data="list" style="width: 100%">
      <el-table-column prop="patienceName" label="接收人"></el-table-column>
      <!-- <el-table-column prop='completedNumber' label='完成人数'  > </el-table-column> -->
      <!-- <el-table-column prop='isRead' label='阅读状态'  > 
          <template slot-scope="scope">
            <div v-if='type==1'>

            {{scope.row.isAnswer==1?'已阅读':'未阅读'}}
            </div>
            <div v-else>
            {{scope.row.isRead==1?'已阅读':'未阅读'}}

            </div>
          </template>
      </el-table-column>-->

      <el-table-column prop="isRead" label="阅读状态" v-if="$route.query.followUpType==2">
        <template slot-scope="scope">{{scope.row.isRead==1?'已阅读':'未阅读'}}</template>
      </el-table-column>
      <el-table-column prop="isAnswer" label="填写状态" v-else>
        <template slot-scope="scope">{{scope.row.isAnswer==1?'已填写':'未填写'}}</template>
      </el-table-column>
      <el-table-column prop="endTime" label="完成时间" v-if="$route.query.followUpType==2"></el-table-column>
      <el-table-column prop="endTime" label="提交时间" v-else></el-table-column>
      <el-table-column label="操作" fixed="right" align="right" header-align="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="goToInfo(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  name: "done",
  data() {
    return {
      programName: "",
      typeName: "",
      type: "",
	  contentName:'',
      form: "",
      list: [],
      key: "",
      select: "",
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateTime: ["", ""],
      status: "",
      search: "",
    };
  },
  mounted() {
    let query = this.$route.query;
    // this.number = query.num
    if (query.id) {
      this.contentId = query.id;
      this.name = query.name;
      this.followUpName = query.followUpName;
      this.type = query.type;
      this.typeName = query.type == 1 ? "问卷" : "宣教";
      this.getPatience();
    }
  },
  methods: {
    async init(ids, type, name) {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/followUp/getFollowUpFeedbackByContentId"),
        method: "post",
        data: {
          contentIds: ids,
          stringParam1: type,
          stringParam2: name,
        },
      });
      console.log(res, "res info");
      if (res.status) {
        this.form = res.data[0];
        this.list = res.data;
        // this.list = res.data
        // let name = ''
        // res.data.forEach(v=>{
        //   name+= v.programName + '，'
        // })

        // this.programName = name.slice(0,name.length-1)
        // console.log(res.data[0].followUpName);
        // await this.getPatience()
      } else {
        this.$message.error("获取失败，请重试");
      }
    },
    async getPatience() {
      // console.log(type,name,'pas');
      //  type = type==''?this.type:type
      //  console.log(type);
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/followUp/getPatienceByFollowUpName"),
        method: "post",
        data: {
          stringParam2: this.followUpName,
          stringParam1: this.type,
          stringParam3: this.search,

          intParam3: this.status,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
          stringParam8: this.contentId,
        },
      });
      console.log(res, "res info");
      if (res.status) {
        this.list = res.data;
        this.totalPage = res.totalCount;
      } else {
        this.$message.error("获取失败，请重试");
      }
    },
    reset() {
      this.status = "";
      this.search = "";
      this.select = "";
      this.dateTime = ["", ""];
    },
    goToInfo(row) {
      if (this.type == 1) {
        // let href =
        //   this.$global.dominH5 +
        //   "dataCollectInfo2.html?id=" +
        //   this.contentId;
        // window.open(href);
        this.$router.push({
          name: "formInfo",
          query: { id: this.contentId, activeName: 3, isFollow: true },
        });
        localStorage.currentPatientFormInfo = JSON.stringify(row);
      } else {
        this.$router.push({
          path: "/zykgl/mission/info",
          query: {
            id: this.contentId,
          },
        });
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getPatience();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getPatience();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.done {
  .search-list {
    display: flex;
    align-items: center;
    .label {
      width: 5%;
    }
  }
  .top {
    font-size: 20px;
    .line {
      display: flex;
    }
    .left {
      width: 80%;
    }
    .item {
      width: 20%;
    }
  }
  .heng {
    width: 100%;
    height: 1px;
    background: #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>